import React from 'react';
import { Table, Image } from "antd";
import { sortByIndex, srotByPrice, sortByDate, convertDate } from "../utils";

const ArticleTable = (props) => {
  const articles = props.articles.map((article, index) => ({
    ...article,
    index: index + 1
  }))

  return (
    <Table columns={columns}
      title={data => {
        const lastRunTime = data && data[0] && data[0].savedDate;
        return `Last run time: ${lastRunTime || ""}`
      }
      }
      dataSource={articles}
      scroll={{ x: 550 }}
      loading={props.isDataFetching} />
  )
}

const columns = [
  {
    title: 'Nr',
    dataIndex: 'index',
    width: 50,
    key: 'index',
    fixed: 'left',
    sorter: {
      compare: sortByIndex,
      multiple: 1,
    }
  },
  {
    title: 'Image',
    dataIndex: 'slika',
    key: 'slika',
    // fixed: 'left',
    render: image =>
      <Image
        width={70}
        src={image}
      />
  },
  {
    title: 'Title',
    dataIndex: 'naslov',
    key: 'naslov',
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sorter: {
      compare: sortByDate,
      multiple: 2,
    },
    render: convertDate
  },
  {
    title: 'Link',
    dataIndex: 'url',
    key: 'url',
    // fixed: 'right',
    width: 90,
    render: url =>
      <a href={url} target="_blank" rel="noopener noreferrer">Link</a>
  },
  {
    title: 'Price',
    dataIndex: 'cijena',
    key: 'cijena',
    fixed: 'right',
    width: 90,
    sorter: {
      compare: srotByPrice,
      multiple: 3,
    }
  }
];

export default ArticleTable;
