import React from 'react';
import ReactDOM from 'react-dom';
import Main from './components/Main';
import "antd/dist/antd.css";
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root')
);
