import axios from 'axios';

const BASE_API_URL = process.env.BASE_API_URL || "https://articles-api.nov0.net"
const ARTICLES_URL = `${BASE_API_URL}/api`;
const TASK_INFO_URL = `${BASE_API_URL}/api/task-info`;
const SEARCH_URL = `${BASE_API_URL}/api/search`;

export const fetchAllArticles = async () => await axios.get(ARTICLES_URL);
export const searchArticles = async () => await axios.post(SEARCH_URL);
export const fetchTaskInfo = async () => await axios.get(TASK_INFO_URL);
