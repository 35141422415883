import moment from 'moment';

const DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const convertDate = (timestamp) => moment(timestamp * 1000).format(DATE_FORMAT);

export const sortByIndex = (a, b) => defaultSort(a.index, b.index);
export const srotByPrice = (a, b) => defaultSort(a.price, b.price);
export const sortByDate = (a,b) => defaultSort(a.timestamp, b.timestamp);

const defaultSort = (a, b) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};
