import React, { useState, useEffect } from 'react';
import { Layout, Menu, Badge } from 'antd';
import ArticleTable from './ArticleTable';
import { fetchAllArticles, searchArticles } from "../api"

const DEFAULT_SELECTED_KEY = '1';

const initialState = {
  articlesForRentSize: 0,
  articlesForSellSize: 0,
  newArticlesForRentSize: 0,
  newArticlesForSellSize: 0,
  articlesForRent: [],
  articlesForSell: [],
  newArticlesForRent: [],
  newArticlesForSell: []
}

const Main = () => {
  const [menuKey, setMenuKey] = useState(DEFAULT_SELECTED_KEY);
  const [isDataFetching, setDataFetching] = useState(false)
  const [articlesResponse, setArticlesResponse] = useState(initialState);

  useEffect(() => {
    executeArticlesFetch(fetchAllArticles);
  }, []);

  const executeArticlesFetch = async (fetchFunction) => {
    setDataFetching(true);
    try {
      const { status, data } = await fetchFunction();
      if (status === 200) {
        setArticlesResponse(data);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setDataFetching(false);
    }
  }

  const handleMenuClick = ({ key }) => {
    if (key === "refresh") {
      executeArticlesFetch(searchArticles);
    } else {
      setMenuKey(key);
    }
  }

  const {
    articlesForRentSize,
    articlesForSellSize,
    newArticlesForRentSize,
    newArticlesForSellSize,
    articlesForRent,
    articlesForSell,
    newArticlesForRent,
    newArticlesForSell,
  } = articlesResponse;

  const selectedArticles = (menuKey) => {
    switch (menuKey) {
      case "1":
        return articlesForRent;
      case "2":
        return newArticlesForRent;
      case "3":
        return articlesForSell;
      case "4":
        return newArticlesForSell;
      default:
        return [];
    }
  }

  return (
    <Layout className="layout">
      <Layout.Header>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[DEFAULT_SELECTED_KEY]}
          selectedKeys={[menuKey]}
          onClick={handleMenuClick}>
          <Menu.Item key="1">
            <Badge count={articlesForRentSize} offset={[0, -12]}>All Rent</Badge>
          </Menu.Item>
          <Menu.Item key="2">
            <Badge count={newArticlesForRentSize} offset={[0, -12]}>New Rent</Badge>
          </Menu.Item>
          <Menu.Item key="3">
            <Badge count={articlesForSellSize} offset={[0, -12]}>All Sell</Badge>
          </Menu.Item>
          <Menu.Item key="4">
            <Badge count={newArticlesForSellSize} offset={[0, -12]}>New Sell</Badge>
          </Menu.Item>
          <Menu.Item key="refresh">
            Refresh data
          </Menu.Item>
        </Menu>
      </Layout.Header>
      <Layout.Content style={{ padding: '0 0' }}>
        <ArticleTable
          articles={selectedArticles(menuKey)}
          isDataFetching={isDataFetching} />
      </Layout.Content>
    </Layout>
  );
}

export default Main;
